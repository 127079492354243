<!-- <div class="wrapper">
    <div class="cbm-nav-navbar">
        <div class="h2 font-weight-bold sidebar-title m-0 p-3 ">CBM</div>
    </div>
    <div class="sidebar" data-color="white"  >
        <sidebar-cmp></sidebar-cmp>
    </div>
    <div class="main-panel">
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div> -->
<router-outlet></router-outlet>

