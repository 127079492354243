import { Injectable } from '@angular/core';
import { RequestProviderService } from './http/http-provider.service'
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class  RefNumGenService {

  constructor(private httpProvider: RequestProviderService) { }

  public generateATMMRNRefNum(sin: string, billAmount: number, billDate: string, userEmail: string) {
    return this.httpProvider.sendPostRequest(
      'generate/atm-mrn',
      {
        'sin' : sin,
        'billAmount' : billAmount,
        'billDate' : billDate,
        'userEmail' : userEmail
      },
      new HttpHeaders,
      new HttpParams
    )
  }

}




