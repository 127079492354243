import { Injectable } from '@angular/core';
import { RequestProviderService } from './http/http-provider.service'
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class  PaymentIpaService {

  constructor(private httpProvider: RequestProviderService) { }

  public getBillNotes(account_number: string, user_email: string) {
    return this.httpProvider.sendPostRequest(
      `get_bill_notes`,
      {account_number, userEmail: user_email },
      new HttpHeaders,
      new HttpParams
    );
  }

  public getIpaDetails(account_number: string, agreement_id: string, user_email: string) {
    return this.httpProvider.sendPostRequest(
      `get_ipa_details`,
      {account_number, agreement_id, userEmail: user_email },
      new HttpHeaders,
      new HttpParams
    );
  }

}



