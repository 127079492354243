<div class="bill-table">
    <ng-container *ngIf="!loading">
        <table id="ddTbl" aria-label="ddTable">
            <thead>
                <tr>
                    <th>Bill Month</th>
                    <th>Bill Amount</th>
                    <th>Bill Status</th>
                    <th>Paid Amount</th>
                    <th>Payment Date</th>
                    <th>Payment Channel*</th>
                    <th>Bill and Paid Amount Remarks</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let bphData of bphDatas">
                    <ng-container *ngIf=" bphData.payments !== undefined">
                        <ng-container *ngIf="bphData.payments.length == 0">
                            <tr>
                                <td>{{bphData.billing_date_parsed | date :'MMMM'}}</td>
                                <td>{{bphData.total_amount | number : '1.2-2'}}</td>
                                <td>{{bphData.recv_status}}</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>{{bphData.with_credit_allocation ? 'Removed Credit Allocation' : ''}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="bphData.payments.length == 1">
                            <tr>
                                <td>{{bphData.billing_date_parsed | date :'MMMM'}}</td>
                                <td>{{bphData.total_amount | number : '1.2-2'}}</td>
                                <td>{{bphData.recv_status}}</td>
                                <td>{{bphData.payments[0].payment_or_credit_amount | number : '1.2-2'}}</td>
                                <td>{{bphData.payments[0].payment_or_credit_date_parsed}}</td>
                                <td>{{bphData.payments[0].payment_channel}}</td>
                                <td>{{bphData.with_credit_allocation ? 'Removed Credit Allocation' : ''}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="bphData.payments.length > 1">
                            <tr>
                                <td>{{bphData.billing_date_parsed | date :'MMMM'}}</td>
                                <td>{{bphData.total_amount | number : '1.2-2'}}</td>
                                <td>{{bphData.recv_status}}</td>
                                <td>{{bphData.payment_or_credit | number : '1.2-2'}}</td>
                                <td colspan="2"> </td>
                                <td>{{bphData.with_credit_allocation ? 'Removed Credit Allocation' : ''}}</td>
                        </tr>
                            <tr *ngFor="let payment of bphData.payments">
                                <td *ngIf="idx!=0" colspan="2"></td>
                                <td>{{bphData.recv_status}}</td>
                                <td>{{payment.payment_or_credit_amount | number : '1.2-2'}}</td>
                                <td>{{payment.payment_or_credit_date_parsed}}</td>
                                <td>{{payment.payment_channel ? payment.payment_channel : '-'}}</td>
                                <td></td>
                            </tr>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="bphData.payments === undefined">
                        <tr>
                            <td>{{bphData.billing_date_parsed | date :'MMMM'}}</td>
                            <td>{{bphData.total_amount | number : '1.2-2'}}</td>
                            <td>{{bphData.recv_status}}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{{bphData.with_credit_allocation ? 'Removed Credit Allocation' : ''}}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf = "bphData.hasOwnProperty('ipa')">
                        <ng-container *ngFor="let i of bphData.ipa">
                            <tr class="ipaRow">
                                <td style="text-align: right;">IPA {{i.order_number}} of {{i.total_ipa_count}}</td>
                                <td>{{i.amount | number : '1.2-2'}}</td>
                                <td>{{i.status}}</td>
                                <td>{{i.paidAmount | number : '1.2-2'}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <tr class="totalRow">
                    <td class="totalLbl">
                        TOTAL
                    </td>
                    <td>
                        {{totalBillAmount | number : '1.2-2'}}
                    </td>
                    <td></td>
                    <td>
                        {{totalPaidAmount | number : '1.2-2'}}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>
<div class="balanceDiv">
    <label class="balLbl">Balance including Installments:</label><span> {{totalBalance | number : '1.2-2'}}</span><br/>
    <label class="balLbl">Balance excluding Installments:</label><span> {{totalBalanceNoInstallment | number : '1.2-2'}}</span><br><br>

    <span class="noteSpan">*If payment channel is blank but with payment details, payment may have been made via Business Center or ADA.</span>
</div>