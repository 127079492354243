import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SINRequestService } from '@shared/services/sin-request.service';

@Component({
  selector: 'app-due-date-view',
  templateUrl: './due-date-view.component.html',
  styleUrls: ['./due-date-view.component.scss']
})
export class DueDateViewComponent implements OnInit {
  @Input() userEmail
  @Input() ddDatas

  constructor() { }

  bphDatas = [];
  totalBillAmount = 0;
  totalPaidAmount = 0;
  totalBalance = 0;
  totalBalanceNoInstallment = 0;
  loading = true

  ngOnInit() {
    this.getData()
  }
  getData(){
    // this.bphDatas = [];
    // this.ipaDatas = [];
    // this.totalBillAmount = 0
    // this.totalPaidAmount = 0
    // this.totalBalance = 0
    // this.totalBalanceNoInstallment = 0
    this.bphDatas = this.ddDatas['bph']
    this.totalBalance = this.ddDatas['balance_w_installments']
    this.totalBalanceNoInstallment = this.ddDatas['balance_wo_installments']

    this.bphDatas.forEach(data => {
      this.totalBillAmount += data['total_amount'] ? parseFloat(data['total_amount']) : 0
      this.totalPaidAmount += parseFloat(data['payment_or_credit'] == "" || data['payment_or_credit'] == undefined ? 0 : data['payment_or_credit'])
      data['billing_date_parsed'] = data['year_month']  == '' ? null : data['year_month'].toString().substr(4,2) + '/01' + '/' + data['year_month'].toString().substr(0,4)
      if(data['payments'] !== undefined){
        data['payments'].forEach(payment => {
          /* istanbul ignore next  Date Format YYYY/MM/DD is not possible entry */
          if (payment['payment_or_credit_date'].length > 8){
            payment['payment_or_credit_date_parsed'] = payment['payment_or_credit_date']  == '' ? null : payment['payment_or_credit_date'].toString().substr(0,2) + '/' + payment['payment_or_credit_date'].toString().substr(3,2) + '/' + payment['payment_or_credit_date'].toString().substr(6,4)
          }else{
            payment['payment_or_credit_date_parsed'] = payment['payment_or_credit_date']  == '' ? null : payment['payment_or_credit_date'].toString().substr(4,2) + '/' + payment['payment_or_credit_date'].toString().substr(6,2) + '/' + payment['payment_or_credit_date'].toString().substr(0,4)
          }
        });
      }

      /* istanbul ignore if  No test data available for this */
      if(data['ipa'] !== undefined){
        data['ipa'].forEach(i => {
          i['paidAmount'] = i['amount'] - i['due_amount']
        })
      }
      this.loading = false
    });
  }
}
