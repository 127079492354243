import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pdf-info',
  templateUrl: './pdf-info.component.html',
  styleUrls: ['./pdf-info.component.scss']
})
export class PdfInfoComponent {

  /* PDF are views are check manually. */
  /* istanbul ignore next */
  constructor(
    public activeModal: NgbActiveModal
  ) { }

}
