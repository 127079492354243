
import { Component, OnInit, Input } from '@angular/core';
import { PaymentIpaService } from "@shared/services/payment-ipa.services";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-payment-ipa',
  templateUrl: './payment-ipa.component.html',
  styleUrls: ['./payment-ipa.component.scss']
})
export class PaymentIpaComponent implements OnInit {

  @Input() account_number
  @Input() agreement_id;

  /*name of the excel-file which will be downloaded. */ 

  /* istanbul ignore next Extract and Export (PDF/CSV) will be tested manually to check the content*/
  exportIPA(): void 
    {
        let  fileName= 'IPA Details.xlsx';  
        /* table id is passed over here */   
        let element = document.getElementById('billNotesTbl'); 
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
  
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
        /* save to file */
        XLSX.writeFile(wb, fileName);
      
    }
    
  constructor(
    public activeModal: NgbActiveModal,
    private paymentIpaService: PaymentIpaService,
  ) {}
  

  billNotesTable = true
  ipaDetailsTable = false
  
  payment_ipa =[]
  bill_notes_list = []
  bill_terms_list = []

  agreement_status:string
  set_up_date:string
  totalAmount:number
  totalDueAmount:number
  totalPendingAmount:number

  ngOnInit() {
    let userEmail = localStorage.getItem('userEmail');
    this.paymentIpaService.getBillNotes(
      this.account_number,      
      userEmail
    ).subscribe(
      resp => {
        console.log(resp)
 
        if ( resp['status'] == 200 ) {    
          let duedateFormat = ''
          let statementDateFormat = ''
          resp['body']['results'].forEach(data=>{
            duedateFormat = data['due_date']  == '' ? null : data['due_date'].toString().substr(4,2) + '/' + data['due_date'].toString().substr(6,2) + '/' + data['due_date'].toString().substr(0,4)
            statementDateFormat = data['statement_date']  == '' ? null : data['statement_date'].toString().substr(4,2) + '/' + data['statement_date'].toString().substr(6,2) + '/' + data['statement_date'].toString().substr(0,4)
            
            data['due_date'] = duedateFormat
            data['statement_date'] = statementDateFormat
          })   

          this.billNotesTable = true
          this.ipaDetailsTable = false

          this.payment_ipa = resp['body']['results']    
          
          this.agreement_id = this.payment_ipa[0]['id_agreement']
          this.set_up_date = this.payment_ipa[0]['statement_date']
          this.agreement_status = this.payment_ipa[0]['agreement_status']

        }else{
          this.clearValues()
        }
      },
      /* istanbul ignore next: cannot replicate api errors on unit testing */
      error => {
        console.log(error)
      }
    )
  }

  getIpaDetails(account_number, agreement_id){
    let userEmail = localStorage.getItem('userEmail');
    this.paymentIpaService.getIpaDetails(
      account_number,
      agreement_id,      
      userEmail
    ).subscribe(
      resp => {
        console.log(resp)
 
        if ( resp['status'] == 200 ) {    
          let duedateFormat = ''
          let statementDateFormat = ''
          this.totalAmount = 0
          this.totalDueAmount = 0 
          this.totalPendingAmount = 0         

          resp['body']['notes'].forEach(data=>{
            duedateFormat = data['due_date']  == '' ? null : data['due_date'].toString().substr(4,2) + '/' + data['due_date'].toString().substr(6,2) + '/' + data['due_date'].toString().substr(0,4)
            statementDateFormat = data['statement_date']  == '' ? null : data['statement_date'].toString().substr(4,2) + '/' + data['statement_date'].toString().substr(6,2) + '/' + data['statement_date'].toString().substr(0,4)
            this.totalPendingAmount =  this.totalPendingAmount + Number(data['bn_pending_amt'])
            data['due_date'] = duedateFormat
            data['statement_date'] = statementDateFormat
          })
          
          let duedateFormat2 = ''
                  

          resp['body']['terms'].forEach(data=>{            
            this.totalAmount = this.totalAmount + data['amount']
            this.totalDueAmount = this.totalDueAmount + data['due_amount']
            duedateFormat2 = data['due_date']  == '' ? null : data['due_date'].toString().substr(4,2) + '/' + data['due_date'].toString().substr(6,2) + '/' + data['due_date'].toString().substr(0,4)                        
            data['due_date'] = duedateFormat2            
          }) 

          this.billNotesTable = false
          this.ipaDetailsTable = true
          this.bill_notes_list = resp['body']['notes']
          this.bill_terms_list = resp['body']['terms']
          
          console.log(this.bill_terms_list)
          console.log(this.bill_notes_list)
          // console.log(totalAmount.toFixed(2))
        }else{
          this.clearValues()
        }
      },
      /* istanbul ignore next: cannot replicate api errors on unit testing */
      error => {
        console.log(error)
      }
    )
  }
  
  clearValues(){
    this.bill_notes_list = []
    this.bill_terms_list = []
    this.agreement_status = ''
    this.set_up_date = ''    
  }

}


