import { Component, Input, OnInit, NgZone, ChangeDetectorRef, HostListener } from '@angular/core';
import { fromEvent, merge, interval, Observable, of, Subscription } from 'rxjs'
import { switchMap, take, skipWhile, takeLast, skipUntil, tap } from 'rxjs/operators'
import { Auth } from 'aws-amplify';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  //seconds
  inactivityTime: number = 300;
  _hasSession = 'logout';
  // windowOpened;

  timeLapsedSinceInactivity: number = 0;
  subscription: Subscription;
  observeable$: Observable<any>;
  mergedObservable$: Observable<any>;

  public inactivityTimerEvent: Array<any>[] = [[document, 'click'], [document, 'wheel'], [document, 'scroll'], [document, 'mousemove'], [document, 'keyup'], [window, 'resize'], [window, 'scroll'], [window, 'mousemove']];

  // @HostListener('window:beforeunload', ['$event'])
  // onBeforeUnload(event: Event) {
  //     alert('are you sure');
  //     // console.log('is unload', 'Signingout');
  //     Auth.signOut();
  //     localStorage.clear();
  // }

  constructor(
    public _ngZone: NgZone
    ) {}


  ngOnInit() {


    let observableArray$: Observable<any>[] = [];
    this.inactivityTimerEvent.forEach(x => {
      observableArray$.push(fromEvent(x[0], x[1]))
    })
    this.mergedObservable$ = merge(...observableArray$);

    this.createObservable();
    // this.windowOpened = this.openWindow();
    
    this._hasSession = this.getCookie('HAS_SESSION') || 'logout';

    if (this._hasSession.toLocaleLowerCase() == 'logout'){
      Auth.signOut();
      localStorage.clear();
    }
    
    this.setCookie('HAS_SESSION', 'login', 0, 'sample');
  }




  createObservable(): void {
    this._ngZone.runOutsideAngular(() => {

      this.observeable$ = this.mergedObservable$
      .pipe(
        switchMap(ev => interval(1000).pipe(take(this.inactivityTime))),
        skipWhile((x) => {
          this.timeLapsedSinceInactivity = x;
          return x != this.inactivityTime - 1
        })
      );

      this.subscribeObservable();
    })

  }

  // openWindow(){
  //  return this.windowOpened = window.open('/', '_blank');
  // }

  // closeWindow(){
  //   return this.windowOpened.close();
  // }

  subscribeObservable() {
    this.subscription = this.observeable$.subscribe((x) => {
      console.log(`subscribed for ${x + 1} sec`);
      this.unsubscribeObservable()
    })
  }

  unsubscribeObservable() {
  //alert('Your session has expired. Press OK to reload the page and reauthenticate.')
  this.subscription.unsubscribe();
  localStorage.clear();
  location.reload();
  }

  private getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return '';
}

private deleteCookie(name) {
    this.setCookie(name, '', -1);
}

private setCookie(name: string, value: string, expireDays: number, path: string = '') {
    let d:Date = new Date();
    // d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    // let expires:string = `expires=${d.toUTCString()}`;
    // let cpath:string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; Secure; HttpOnly; SameSite=Strict`;
}

}