import { Injectable } from '@angular/core';
import { AdminProviderService } from '@shared/services/http/http-provider.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private httpProvider: AdminProviderService) { }
  
  public searchUser(queryString: string) {
    return this.httpProvider.sendPostRequest(`/user/search/`, {queryString: queryString}, new HttpHeaders, new HttpParams);
  }

}
