import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SINRequestService } from '@shared/services/sin-request.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-billing-payment-history',
  templateUrl: './billing-payment-history.component.html',
  styleUrls: ['./billing-payment-history.component.scss']
})
export class BillingPaymentHistoryComponent implements OnInit {
  @Input() account_number
  @Input() searchSIN
  @Input() userEmail

  constructor(
    public activeModal: NgbActiveModal,
    private sinRequestService: SINRequestService,
  ) { }

  ipaValue ='MERALCO_ONLINE';
  bphDatas = [];
  ipaDatas = [];
  yearsData = [];
  totalBillAmount = 0;
  totalPaidAmount = 0;
  totalBalance = 0;
  totalBalanceNoInstallment = 0;
  viewYear = new Date().getFullYear();
  currentYear = new Date().getFullYear();
  loading = true;
  CAN = '-';

  ngOnInit() {

    //Increamental Year up to the latest year
    for (this.currentYear;  this.currentYear >= 2019; this.currentYear--){
      this.yearsData.push(this.currentYear)
    }

    this.getData()
  }

    /*name of the excel-file which will be downloaded. */ 
    // fileName= 'Billing and payment Details.xlsx';  
    /* istanbul ignore next Export will be test manually*/
    exportIPA(): void 
      {
        if(this.ipaValue == 'MERALCO_ONLINE'){
          /* table id is passed over here */   
          let element = document.getElementById('moTbl'); 
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
    
          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
          /* save to file */
          XLSX.writeFile(wb, 'Default View' + ' - ' + this.searchSIN + ' - ' + this.viewYear + '.xlsx');
        }else if(this.ipaValue == 'AFFECTED_BILL'){
          /* table id is passed over here */   
          let element = document.getElementById('abTbl'); 
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

          /* save to file */
          XLSX.writeFile(wb, 'Affected Bill View' + ' - ' + this.searchSIN + ' - ' + this.viewYear + '.xlsx');
        }else if(this.ipaValue =='DUE_DATE'){
          /* table id is passed over here */   
          let element = document.getElementById('ddTbl'); 
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

          /* save to file */
          XLSX.writeFile(wb, 'Due Date View' + ' - ' + this.searchSIN + ' - ' + this.viewYear + '.xlsx');
        }
      }

    changeYear() {
      this.getData()
      this.loading = true
    }

    getData() {
      this.loading = true
      // this.bphDatas = [];
      this.ipaDatas = [];
      this.totalBillAmount = 0
      this.totalPaidAmount = 0
      this.totalBalance = 0
      this.totalBalanceNoInstallment = 0
      this.CAN = ''
      this.sinRequestService.getBillingPaymentsHistory(this.searchSIN, this.ipaValue, this.viewYear, this.userEmail).subscribe(res =>{
        this.bphDatas = res['body']['results']
        this.CAN = res['body']['results']['CAN']
        this.loading = false
      })
    }

}
