
<div class="sidebar-wrapper">
  <div class="logo">
    <a class="simple-text logo-mini">
    </a>
    <a class="simple-text logo-normal">
      CBM
    </a>
  </div>
    <ul class="nav">
        <!-- <li *ngFor="let menuItem of menuItems" routerLinkActive="active" class="{{menuItem.class}}">
            <a [routerLink]="[menuItem.path]">
                <i class="nc-icon {{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li> -->
    </ul>
</div>
