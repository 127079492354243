import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponentComponent } from '@shared/loading-component/loading-component.component';
import { RefnumGeneratorComponent } from './modals/refnum-generator/refnum-generator.component';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PdfInfoComponent } from './modals/pdf-info/pdf-info.component';
import { PaymentHistoryComponent } from './modals/payment-history/payment-history.component';
import { PaymentIpaComponent } from './modals/payment-ipa/payment-ipa.component';
import { BillingPaymentHistoryComponent } from './modals/billing-payment-history/billing-payment-history.component';
import { MeralcoOnlineViewComponent } from './modals/billing-payment-history/meralco-online-view/meralco-online-view.component';
import { AffectedBillViewComponent } from './modals/billing-payment-history/affected-bill-view/affected-bill-view.component';
import { DueDateViewComponent } from './modals/billing-payment-history/due-date-view/due-date-view.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        LoadingComponentComponent
    ],
    declarations: [
        LoadingComponentComponent,
        RefnumGeneratorComponent,
        PdfInfoComponent,
        PaymentHistoryComponent,
        PaymentIpaComponent,
        BillingPaymentHistoryComponent,
        MeralcoOnlineViewComponent,
        AffectedBillViewComponent,
        DueDateViewComponent
    ],
    providers: [
        DatePipe
    ]
})
export class SharedModule { }
