<div class="modal-content">
    <label (keyup)="''" class="close" (click)="activeModal.dismiss('cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </label>
  <div class="modal-header header">IPA Details
  </div> 
    <hr>
    <div *ngIf="billNotesTable" class="history-table bill-notes-table">
        <table aria-label="billNotesTble">
            <thead>
                <tr>
                    <th id="account_num" container="body" placement="right" ngbTooltip="" >
                        Account Number
                    </th>
                    <th id="id_agreement" container="body" placement="top" ngbTooltip="" >
                        Agreement ID
                    </th>
                    <th id="invoice" container="body" placement="top" ngbTooltip="" >
                        Invoice
                    </th>
                    <th id="due_date" container="body" placement="top" ngbTooltip="" >
                        Due Date
                    </th>
                    <th id="statement_date" container="body" placement="top" ngbTooltip="" >
                        Statement Date
                    </th>                  
                </tr>
            </thead>
            <tbody>
                <tr (keyup)="''" *ngFor="let rec of payment_ipa" (click)="getIpaDetails(rec.account_num, rec.id_agreement)" style="cursor: pointer;">
                    <td>
                        {{rec.account_num || '-'}}
                    </td>
                    <td>
                        {{rec.id_agreement || '-'}}
                    </td>
                    <td>
                        {{rec.invoice || '-'}}
                    </td>
                    <td>
                        {{rec.due_date || '-'}}
                    </td>
                    <td>
                        {{rec.statement_date || '-'}}
                    </td>              
                </tr>
            </tbody>
          </table>
    </div>
    
    <div *ngIf="ipaDetailsTable" class="ipa-details-table">
        <div style="padding-left: 10px;">
            <label class="sinLbl">
                Agreement: {{this.agreement_id}}
            </label>
        </div>
        <div style="padding-left: 10px;">
            <label class="sinLbl">
                Set Up Date: {{this.set_up_date}}
            </label>
        </div>
        <div style="padding-left: 10px;">
            <label class="sinLbl">
                Agreement Status: {{this.agreement_status}}
            </label>
        </div>
        <br/><br/>
        <div class="ipaDetailsDiv">
            <label class="sinLbl">
                Bill Notes
            </label>
            <button type="button" class="exportExcelBtn" (click)="exportIPA()">Export to Excel</button>
        </div>
        <div class="history-table">
            <table  id="billNotesTbl" aria-label="historyTble">
                <thead>
                    <tr>
                        <th id="invoice" container="body">
                            Invoice
                        </th>
                        <th id="statement_date" container="body">
                            Statement Date
                        </th>
                        <th id="due_date" container="body">
                            Due Date
                        </th>
                        <th id="service" container="body">
                            Service
                        </th>
                        <th id="bill_note_type" container="body">
                            Bill Note Type
                        </th>
                        <th id="bn_pending_amt" container="body">
                            Bill Note Pending Amount
                        </th>
                        <th id="recv_status" container="body">
                            Receivable Status
                        </th>                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let list of bill_notes_list" class="sin-list-table">
                        <td>
                            {{list.invoice|| '-'}}
                        </td>
                        <td>
                            {{list.statement_date}}
                        </td>
                        <td>
                            {{list.due_date}}
                        </td>
                        <td>
                            {{list.service}}
                        </td>
                        <td>
                            {{list.bill_note_type}}
                        </td>
                        <td>
                            {{list.bn_pending_amt}}
                        </td>
                        <td>
                            {{list.recv_status}}
                        </td>                         
                    </tr>
                    <tr class="sin-list-table">
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            {{this.totalPendingAmount.toFixed(2)}}
                        </td>
                        <td>
                            
                        </td>                         
                    </tr>
                </tbody>
            </table>
        </div>
        <br/><br/>
        <div class="ipaDetailsDiv">
            <label class="sinLbl">
                Terms
            </label>
            <button type="button" class="exportExcelBtn" (click)="exportIPA()">Export to Excel</button>
        </div>
        <div class="history-table">
            <table id="termsTbl" aria-label="termsTble">
                <thead>
                    <tr>
                        <th id="order_num" container="body">
                            Order Number
                        </th>
                        <th id="amount" container="body">
                            Amount
                        </th>
                        <th id="list_rate" container="body">
                            Due Amount
                        </th>
                        <th id="list_rate" container="body">
                            Status
                        </th>
                        <th id="list_rate" container="body">
                            Due Date
                        </th>                 
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let list of bill_terms_list" class="sin-list-table">
                        <td>
                            {{list.order_number|| '-'}}
                        </td>
                        <td>
                            {{list.amount}}
                        </td>
                        <td>
                            {{list.due_amount}}
                        </td>
                        <td>
                            {{list.status}}
                        </td>
                        <td>
                            {{list.due_date}}
                        </td>                    
                    </tr>
                    <tr class="sin-list-table">
                        <td>
                            
                        </td>
                        <td>
                            {{this.totalAmount.toFixed(2)}}
                        </td>
                        <td>
                            {{this.totalDueAmount.toFixed(2)}}
                        </td>
                        <td>
                           
                        </td>
                        <td>
                           
                        </td>                    
                    </tr>
                </tbody>
            </table>
        </div>                        
      </div>
</div>


