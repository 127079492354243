import { Injectable } from '@angular/core';
import { RequestProviderService } from './http/http-provider.service'
import { HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SINRequestService {

  constructor(private httpProvider: RequestProviderService) { }

  public checkPDFUserRequest(user_email:string){
    return this.httpProvider.sendGetRequest(`check_access?userEmail=${user_email}`, new HttpHeaders, new HttpParams);
  }

  public checkMRNUserRequest(user_email:string){
    return this.httpProvider.sendGetRequest(`check_mrn_access?userEmail=${user_email}`, new HttpHeaders, new HttpParams);
  }

  public checkVersion(){
    return this.httpProvider.sendGetRequest(`check_version`, new HttpHeaders, new HttpParams);
  }
  
  public getSINRequest(sin:any, user_email:string){
    return this.httpProvider.sendPostRequest(`sin`,
    { sin , userEmail: user_email},
    new HttpHeaders,
     new HttpParams);
  }

  public getPDFRequest(sin:any, user_email:string){
    return this.httpProvider.sendPostRequest(`pdf_print`,
    {sin, userEmail: user_email}
    , new HttpHeaders, new HttpParams);
  }

  public getLetterRequest(sin:any, user_email:string){
    return this.httpProvider.sendPostRequest(`get_mba_pdf`,
    {sin, userEmail: user_email},
    new HttpHeaders,
    new HttpParams);
  }

  public getBillingPaymentsHistory(sin:any, view_type:any, year:any, user_email:string){
    return this.httpProvider.sendPostRequest(`get_billing_payments`,
    { sin, view_type, year, user_email},
    new HttpHeaders,
    new HttpParams)
  }

}