<div class="bill-table">
    <ng-container>
        <table id="moTbl" aria-label="moTable">
            <thead>
                <tr>
                    <th>Bill Month</th>
                    <th>Bill Amount</th>
                    <th>Bill Status</th>
                    <th>Paid Amount</th>
                    <th>Payment Date</th>
                    <th>Payment Channel*</th>
                    <th>Bill and Paid Amount Remarks</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let bphData of bphDatas">
                    <ng-container *ngIf="bphData.payments.length == 0">
                        <tr>
                            <td>{{bphData.billing_date_parsed | date :'MMMM'}}</td>
                            <td>{{bphData.total_amount | number : '1.2-2'}}</td>
                            <td>{{bphData.recv_status}}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{{bphData.with_credit_allocation ? 'Removed Credit Allocation' : ''}}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="bphData.payments.length == 1">
                        <tr>
                            <td>{{bphData.billing_date_parsed | date :'MMMM'}}</td>
                            <td>{{bphData.total_amount | number : '1.2-2'}}</td>
                            <td>{{bphData.recv_status}}</td>
                            <td>{{bphData.payments[0].payment_or_credit_amount | number : '1.2-2'}}</td>
                            <td>{{bphData.payments[0].payment_or_credit_date_parsed}}</td>
                            <td>{{bphData.payments[0].payment_channel}}</td>
                            <td>{{bphData.with_credit_allocation ? 'Removed Credit Allocation' : ''}}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="bphData.payments.length > 1">
                        <tr>
                            <td>{{bphData.billing_date_parsed | date :'MMMM'}}</td>
                            <td>{{bphData.total_amount | number : '1.2-2'}}</td>
                            <td>{{bphData.recv_status}}</td>
                            <td>{{bphData.payment_or_credit | number : '1.2-2'}}</td>
                            <td colspan="2"> </td>
                            <td>{{bphData.with_credit_allocation ? 'Removed Credit Allocation' : ''}}</td>
                        </tr>
                        <tr *ngFor="let payment of bphData.payments">
                            <td *ngIf="idx!=0" colspan="2"></td>
                            <td>{{bphData.recv_status}}</td>
                            <td>{{payment.payment_or_credit_amount | number : '1.2-2'}}</td>
                            <td>{{payment.payment_or_credit_date_parsed}}</td>
                            <td>{{payment.payment_channel ? payment.payment_channel : '-'}}</td>
                            <td></td>
                        </tr>
                    </ng-container>
                </ng-container>
                <ng-container *ngFor="let ipaData of ipaDatas">
                    <ng-container *ngIf="ipaData.billing_information.payments.length == 0">
                        <tr class="ipaMonthRow">
                            <td>{{ipaData.billing_information.billing_date_parsed | date :'MMMM'}}</td>
                            <td>{{ipaData.billing_information.total_amount | number : '1.2-2'}}</td>
                            <td>{{ipaData.billing_information.recv_status}}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{{ipaData.billing_information.with_credit_allocation ? 'Removed Credit Allocation' : ''}}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="ipaData.billing_information.payments.length == 1">
                        <tr class="ipaMonthRow">
                            <td>{{ipaData.billing_information.billing_date_parsed | date :'MMMM'}}</td>
                            <td>{{ipaData.billing_information.total_amount | number : '1.2-2'}}</td>
                            <td>{{ipaData.billing_information.recv_status}}</td>
                            <td>{{ipaData.billing_information.payments[0].payment_or_credit_amount | number : '1.2-2'}}</td>
                            <td>{{ipaData.billing_information.payments[0].payment_or_credit_date_parsed}}</td>
                            <td>{{ipaData.billing_information.payments[0].payment_channel}}</td>
                            <td>{{ipaData.billing_information.with_credit_allocation ? 'Removed Credit Allocation' : ''}}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="ipaData.billing_information.payments.length > 1">
                        <tr class="ipaMonthRow">
                            <td>{{ipaData.billing_information.billing_date_parsed | date :'MMMM'}}</td>
                            <td>{{ipaData.billing_information.total_amount | number : '1.2-2'}}</td>
                            <td>{{ipaData.billing_information.recv_status}}</td>
                            <td>{{ipaData.billing_information.payment_or_credit | number : '1.2-2'}}</td>
                            <td colspan="2"> </td>
                            <td>{{ipaData.billing_information.with_credit_allocation ? 'Removed Credit Allocation' : ''}}</td>
                        </tr>
                        <tr class="ipaMonthRow" *ngFor="let payment of ipaData.billing_information.payments">
                            <td *ngIf="idx!=0" colspan="2"></td>
                            <td>{{ipaData.billing_information.recv_status}}</td>
                            <td>{{payment.payment_or_credit_amount | number : '1.2-2'}}</td>
                            <td>{{payment.payment_or_credit_date_parsed}}</td>
                            <td>{{payment.payment_channel ? payment.payment_channel : '-'}}</td>
                            <td></td>
                        </tr>
                    </ng-container>
                    <ng-container *ngFor="let ipa of ipaData.ipa_list">
                        <ng-container *ngFor="let i of ipa.ipas">
                            <tr class="ipaRow" *ngIf="ipaDatas.length > 0">
                            <td style="text-align: right;">IPA {{i.order_number}} of {{i.total_ipa_count}}</td>
                            <td>{{i.amount | number : '1.2-2'}}</td>
                            <td>{{i.status}}</td>
                            <td>{{i.paidAmount | number : '1.2-2'}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <!-- <td>{{i.due_date_parsed}}</td>
                            <td>{{ipa.paymentChannel}}</td> -->
                            </tr>
                        </ng-container>
                    </ng-container>
                    <!-- <ng-container *ngFor="let i of ipaData.ipas">
                        <tr class="ipaRow" *ngIf="ipaDatas.length > 0">
                            <td style="text-align: right;">IPA {{i.order_number}} of {{ipaData.ipas.length}}</td>
                            <td>{{i.amount}}</td>
                            <td>{{i.status}}</td>
                            <td>{{i.paidAmount}}</td>
                            <td>{{ipaData.due_date}}</td>
                            <td>{{ipaData.paymentChannel}}</td>
                        </tr>
                    </ng-container> -->
                </ng-container>
                <tr class="totalRow">
                    <td class="totalLbl">
                        TOTAL
                    </td>
                    <td>
                        {{totalBillAmount | number : '1.2-2'}}
                    </td>
                    <td>

                    </td>
                    <td>
                        {{totalPaidAmount | number : '1.2-2'}}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>
<div class="balanceDiv">
    <label class="balLbl">Balance including Installments:</label><span> {{totalBalance | number : '1.2-2'}}</span><br/>
    <label class="balLbl">Balance excluding Installments:</label><span> {{totalBalanceNoInstallment | number : '1.2-2'}}</span><br><br>

    <span class="noteSpan">*If payment channel is blank but with payment details, payment may have been made via Business Center or ADA.</span>
</div>