
import { Component, OnInit, Input } from '@angular/core';
import { RefNumGenService } from '@shared/services/refnumgen.service';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-refnum-generator',
  templateUrl: './refnum-generator.component.html',
  styleUrls: ['./refnum-generator.component.scss']
})
export class RefnumGeneratorComponent implements OnInit {

  @Input() SIN;

  constructor(
    public activeModal: NgbActiveModal,
    private refNumGenService: RefNumGenService,
    private datePipe: DatePipe
  ) {}

  loading: boolean;
  atmRefNum: string;
  mrnRefNum: string;
  billDate: any;
  billAmount: any;
  error: boolean;
  dtToday: string;

  copyTextBox(inputElement) {
    inputElement.select();
    document.execCommand('copy');
  }

  generateRefNum() {
    let userEmail = localStorage.getItem('userEmail');
    this.loading = true;
    this.refNumGenService.generateATMMRNRefNum(
      this.SIN,
      parseFloat(this.billAmount),
      this.billDate,
      userEmail
    ).subscribe(
      resp => {
        this.loading = false;
        if ( resp['status'] == 200 ) {
          this.error = false;
          this.atmRefNum = resp['body']['atmRefNum']
          this.mrnRefNum = resp['body']['mrnRefNum']
        }
      },
      /*  istanbul ignore next: cannot replicate api errors on unit testing */
      error => {
        this.loading = false;
        this.error = true;
      }
    )

  }

  ngOnInit() {
    this.billAmount = "";
    this.loading = false;
    this.error = false;
    this.mrnRefNum = "";
    this.atmRefNum = "";
    
    this.dtToday = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.billDate = this.dtToday;
  }
 

}


