import { Injectable } from '@angular/core';
import { RequestProviderService } from './http/http-provider.service'
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class  PaymentHistoryService {

  constructor(private httpProvider: RequestProviderService) { }

  public getPaymentHistory(statement_number: string, user_email: string) {
    return this.httpProvider.sendPostRequest(
      `get_payment_history`,
      {statement_number, userEmail: user_email},
      new HttpHeaders,
      new HttpParams
    );
  }

}



