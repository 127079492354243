<div class="modal-content">
    <label (keyup)="''" class="close" (click)="activeModal.dismiss('cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </label>
  <div class="modal-header header">Payments History
    <button type="button" class="exportExcelBtn" (click)="exportPH()">Export to Excel</button>
  </div> 
    <hr>
    <div class="history-table">
        <table id="historyTbl" aria-label="historyTable">
            <thead>
                <tr>
                    <th id="date" container="body" placement="right" ngbTooltip="" >
                        Date
                    </th>
                    <th id="paid_amount" container="body" placement="top" ngbTooltip="" >
                        Paid Amount
                    </th>
                    <th id="payment_method" container="body" placement="top" ngbTooltip="" >
                        Payment Method
                    </th>              
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rec of history_record">
                    <td>
                        {{rec.payment_or_credit_date || '-'}}
                    </td>
                    <td>
                        {{rec.payment_or_credit_amount || '-'}}
                    </td>
                    <td>
                        {{rec.payment_mode|| '-'}}
                    </td>              
                </tr>
                <tr>
                    <td>  
                    </td>
                    <td>
                        {{this.totalAmount || '0' | number:'1.2-2' }}
                    </td>
                    <td>
                    </td>              
                </tr>
            </tbody>
          </table>
    </div>    
</div>


