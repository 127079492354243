<div class="modal-content">
  <div class="modal-header header">
    <div class="modal-title">
      ATM RN / MRN Generator
    </div>

    <button type="button" class="close" (click)="activeModal.dismiss('cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body mbody">
    <div class="row mb-2">
      <div class="col mb-2">
        <label>Amount to be Paid</label>
        <input type="number" step="0.01:" id="billAmount" placeholder="000.0" [(ngModel)]="billAmount"/>
      </div>
      <div class="col mb-2">
        <label>Bill Date</label>
        <input type="date" [min]="dtToday" disabled [(ngModel)]="billDate"/>
      </div>
    </div>

    <div *ngIf="error" class="row mb-2">
      <div class="col mb-4 center" style="color:red; font-weight: 900;">
        Invalid SIN
      </div>
    </div>

    <div *ngIf="!loading" class="row mb-2">
      <div class="col mb-4 center">
        <button class="btn" (click)="generateRefNum()" [disabled]="billAmount == '' || billAmount == none">Generate</button>
      </div>
    </div>

    <div *ngIf="loading" class="row mb-2">
      <div class="container">        
        <div class="loading">
          <app-loading-component></app-loading-component>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col mb-2">
        <label>ATM Reference Number</label>
        <div class="refnum">
          <input type="text"  [(ngModel)]="atmRefNum" #atmRefNumInput readonly="readonly"/>
          <img (keyup)="''" src="assets/img/copy.png" (click)="copyTextBox(atmRefNumInput)" alt="copy_to_clipboard">
        </div>
      </div>
      <div class="col mb-2">
        <label>Meralco Reference Number</label>
        <div class="refnum">
          <input type="text"  [(ngModel)]="mrnRefNum" #mrnRefNumInput readonly="readonly"/>
          <img (keyup)="''" src="assets/img/copy.png" (click)="copyTextBox(mrnRefNumInput)" alt="copy_to_clipboard">
        </div>
      </div>
    </div>

  </div>
</div>


