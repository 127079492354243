const signInConfig = {
  WEB_PROTOCOL:'https://',
  DOMAIN_NAME: 'sin-inquiry.auth.ap-southeast-1.amazoncognito.com',
  CLIENT_ID: '31jvm9ps27dldhdki8nor2plg8',
  RESPONSE_TYPE: 'token',  // or 'token', note that REFRESH token will only be generated when the responseType is code
  REDIRECT_SIGN_IN: 'https://sin-inquiry.meco-soldel.com/login',
  REDIRECT_SIGN_OUT: 'https://sin-inquiry.meco-soldel.com/',
  SCOPE: ['email', 'openid'],
}

export const environment = {
  production: true,

  homepage: '/sin-inquiry/view',
  hostURL: 'https://sininquiry-api.meco-soldel.com/prod',

  // AMPLIFY environments
  domainName: `${signInConfig.DOMAIN_NAME}`,
  clientId: `${signInConfig.CLIENT_ID}`,
  responseType: `${signInConfig.RESPONSE_TYPE}`,
  redirectUri: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignIn: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignOut: `${signInConfig.REDIRECT_SIGN_OUT}`,
  scope: `${signInConfig.SCOPE}`,
  signIn: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/login?client_id=${signInConfig.CLIENT_ID}&response_type=${signInConfig.RESPONSE_TYPE}&scope=email+openid&redirect_uri=${signInConfig.REDIRECT_SIGN_IN}`,
  signOut: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/logout?client_id=${signInConfig.CLIENT_ID}&logout_uri=${signInConfig.REDIRECT_SIGN_OUT}`
};
